.horizontal-scrolling-text-bar {
    overflow: hidden;
  }
  
  .text-container {
    white-space: nowrap;
    animation: scrolling 20s linear infinite;
  }
  
  .text-item {
    font-size: 20px;
    font-weight: 600;
    display: inline-block;
    margin-right: 20px; /* Adjust margin as needed */
  }
  
  @keyframes scrolling {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  