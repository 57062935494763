.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.timeline {
  list-style: none;
  padding: 0;
}

.timeline-item {
  position: relative;
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.timeline-item:hover {
  background-color: #f0f0f0;
}

.timeline-marker {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.timeline-content {
  margin-left: 30px;
}

.timeline-date {
  font-size: 14px;
  color: #555;
}

.timeline-event {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.backgroundText {
  position: absolute;
  bottom: -100px;
  margin:0;
  padding:0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  z-index: -1;
  color: #f1f1f1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  user-select: none;
  font-size:200px
}

.cont{
  border-left: 2px solid #000;
}

.timelineCircle{
  width: 6px;
  height: 6px;
  background-color: #000; /* Set your desired background color */
  border-radius: 50%;
  position: absolute;
  margin-top:100px;
  margin-left:-16px;
}

.timelineCard:hover{
  background-color: #fdfdfd;
  & .timelineCircle{
    background-color: white;
  }
} 


.filtering-message {
  color: green;
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.accordion-button{
  padding-left: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  .xsFontSize70{
    font-size: 70%;
  }
  .xsFontSize50{
    font-size: 50%;
  }
  .xsFontSize90{
    font-size: 90%;
  }
  .xsMarginLeft{
    margin-left:5px
  }
  .xsMarginRight{
    margin-right:5px
  }
  .xsMarginTop{
    margin-top: 10px;
  }
  .xsCenter{
    justify-content: center;
  }
  .xsFullSize{
    width:100%
  }
}