.project-container {
  position: relative;
  overflow: hidden;
  border: 5px;
  @media (max-width: 768px) {
    /* Apply styles for screens up to 768px width (adjust as needed) */
    position: static; /* Remove absolute positioning on smaller screens */
    transform: none; /* Remove transformations on smaller screens */
    margin-bottom: 20px; /* Add some spacing between project containers on smaller screens */
  }
}
.project-container:hover {
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  @media (max-width: 768px) {
    /* Apply styles for screens up to 768px width (adjust as needed) */
    width: 100%; /* Make the image container full width on smaller screens */
  }
}

.project-image {
  width: 100%;
  height: auto;
  display: block;
  transition: filter 0.3s ease;
  border-radius: 10px;
  @media (max-width: 768px) {
    /* Apply styles for screens up to 768px width (adjust as needed) */
    position: static; /* Remove absolute positioning on smaller screens */
    width: 100%; /* Make the overlay card full width on smaller screens */
    margin-top: 10px; /* Add some spacing between image and text on smaller screens */
  }
}

.overlay-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  color: white;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
}

.project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Move it initially outside the container */
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
  opacity: 0;
  transition: left 0.5s ease, opacity 1s ease; 
  border-radius: 10px;/* Add left transition */
  
}

.project-container:hover .overlay-card,
.project-container:hover::before {
  left: 0; /* Move the box into the container */
  opacity: 1;
  border-radius: 10px;
  
}

  /* Modify or add the following styles */
.project-highlights {
    display: flex;
    flex-wrap: wrap; /* Allow highlights to wrap to the next line if needed */
    margin-bottom: 10px;
  }
  
  
  /* Add the following styles for small size and white border */
  .project-highlight .btn {
    font-size: 13px;
    padding: 3px 8px;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    background-color: #444649;
    cursor: default;
  }
 
.project-achievements {
    display: flex;
    flex-wrap: wrap; /* Allow highlights to wrap to the next line if needed */
    margin-bottom: 10px;
  }
  
  
  /* Add the following styles for small size and white border */
  .project-achievement .btn {
    padding: 3px 8px;
    border-radius: 5px;
    border: 1px solid white;
    color: #fff;
    background-color: #2c21fb;
    cursor: default;
    font-style: italic;
  }
  .linker:hover {
    color: #2c21fb;
    transform: scale(1.3);
  }